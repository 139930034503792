<script setup lang="ts">
import { VListItem } from "vuetify/components/VList";
import type { FunctionalComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";

interface Props {
  title?: string;
  subtitle?: string;
  to?: unknown;
  href?: string;
  exact?: boolean;
  appendIcon?: string | (new () => any) | FunctionalComponent;
  prependIcon?: string | (new () => any) | FunctionalComponent;
  active?: boolean;
  lines?: "one" | "two" | "three";
  density?: "default" | "comfortable" | "compact";
  disabled?: boolean;
  link?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  subtitle: undefined,
  to: undefined,
  href: undefined,
  exact: undefined, // component will break without this default
  appendIcon: undefined,
  prependIcon: undefined,
  lines: undefined,
  density: undefined,
  disabled: undefined,
  link: undefined,
});
</script>

<template>
  <VListItem v-bind="{ ...props, to: props.to as RouteLocationRaw }">
    <template
      v-if="$slots['title']"
      #title
    >
      <slot name="title" />
    </template>
    <template
      v-if="$slots['subtitle']"
      #subtitle
    >
      <slot name="subtitle" />
    </template>
    <template
      v-if="$slots['prepend']"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots['append']"
      #append
    >
      <slot name="append" />
    </template>
    <template
      v-if="$slots['default']"
      #default
    >
      <slot />
    </template>
  </VListItem>
</template>
